import React, { memo, useMemo } from "react";
import { useTranslation } from "react-i18next";

import { readState } from "@/__main__/app-state.mjs";
import { MAX_MATCHES } from "@/game-deadlock/constants/enums.mjs";
import usePlayerHeroStatsAll from "@/game-deadlock/utils/use-player-hero-stats.mjs";
import type { SharedStatsBreakdownTypes } from "@/shared/stats-breakdown-types.mjs";
import SharedStatsBreakdown from "@/shared/StatsBreakdown.jsx";
import { filterErrState } from "@/util/eval-state.mjs";
import { sanitizeNumber } from "@/util/helpers.mjs";
import { formatToFixedNumber, formatToPercent } from "@/util/i18n-helper.mjs";
import { useSnapshot } from "@/util/use-snapshot.mjs";

export default memo(function MatchTileStatistics(props: {
  matchId: string;
  steamId: string;
}) {
  const stats = useStatsBreakdown(props);
  // @ts-ignore JSX component with terrible inherited types
  return React.createElement(SharedStatsBreakdown, stats);
});

function useStatsBreakdown({
  matchId,
  steamId,
}: {
  matchId: string;
  steamId: string;
}): SharedStatsBreakdownTypes {
  const { t } = useTranslation();
  const {
    deadlock: { matchlistMeta },
  } = useSnapshot(readState);
  const player = useMemo(
    () => filterErrState(matchlistMeta[steamId]?.[matchId])?.player,
    [steamId, matchId, matchlistMeta],
  );
  const dataPoint = useMemo(() => {
    const stats = player?.stats?.at?.(-1);
    const totalShots =
      sanitizeNumber(stats?.shots_missed) + sanitizeNumber(stats?.shots_hit);
    return {
      kills: player?.kills,
      income: player?.net_worth,
      accuracy: stats?.shots_hit / totalShots,
      damage: stats?.player_damage,
      hs: stats?.hero_bullets_hit_crit / stats?.hero_bullets_hit,
    };
  }, [player]);
  const playerStats = usePlayerHeroStatsAll(steamId);
  const result = useMemo<SharedStatsBreakdownTypes>(() => {
    return {
      rowData: [
        [
          {
            title: t("common:stats.kills", "Kills"),
            dataPoint: dataPoint.kills,
            recentAvg: playerStats.kills / playerStats.games_played,
          },
          {
            title: t("deadlock:stats.souls", "Souls"),
            dataPoint: dataPoint.income,
            recentAvg: playerStats.net_worth / playerStats.games_played,
          },
          {
            title: t("common:accuracy", "Accuracy"),
            dataPoint: dataPoint.accuracy,
            recentAvg:
              playerStats.shots_hit /
              (playerStats.shots_hit + playerStats.shots_missed),
            formatter(language, accuracy) {
              return accuracy > 0
                ? t("lol:percent", "{{number}}%", {
                    number: (accuracy * 100).toLocaleString(language, {
                      minimumFractionDigits: 1,
                      maximumFractionDigits: 1,
                    }),
                  })
                : "-";
            },
          },
          {
            title: t("common:stats.damage", "Damage"),
            dataPoint: dataPoint.damage,
            recentAvg: playerStats.player_damage / playerStats.games_played,
          },
          {
            title: t("common:stats.headshots", "Headshots"),
            dataPoint: dataPoint.hs,
            recentAvg:
              playerStats.hero_bullets_hit_crit / playerStats.hero_bullets_hit,
            formatter: formatToPercent,
          },
        ].map((i) => ({
          ...i,
          dataPoint: sanitizeNumber(i.dataPoint),
          recentAvg: sanitizeNumber(i.recentAvg),
          formatter: i.formatter ?? formatterDefault,
        })),
      ],
      sections: [
        {
          leftTitleText: t("common:you", "You"),
          centerTitleText: t("common:general", "General"),
          rightTitleText: t(
            "common:recentnAvg",
            "Recent {{numberOfGames}} Avg.",
            {
              numberOfGames: Math.min(
                sanitizeNumber(playerStats.games_played),
                MAX_MATCHES,
              ),
            },
          ),
          comparisonOptions: [],
          selectedComparison: "",
          onChangeComparison: () => {},
        },
      ],
    };
  }, [dataPoint, playerStats, t]);
  return result;
}

function formatterDefault(language, value: number): number | string {
  return Number.isNaN(value) ? "-" : formatToFixedNumber(language, value, 1);
}
